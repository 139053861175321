<template>
  <div class="page404-wrap">
    <p>The page you have requested does not exist on our website.</p>
    <br>
    <p>Go to our <router-link to="/">Home Page</router-link> instead.</p>
  </div>
</template>

<script>
export default {

}
</script>

<style>

.page404-wrap {
  height: 80vh;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 0 2em;

  text-align: center;
}

</style>