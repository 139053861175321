<template>
    <div class="modal-wrap" :class="{ show : modal.show, error : modal.error }">

        <h4 class="modal-title">{{ modal.title }}</h4>
        <p v-if="modal.body !== ''" class="modal-body" v-html="modal.body"></p>


        <button @click="closeModal" class="btn">Okay</button>

    </div>
</template>

<script>
export default {

    name: 'Modal',

    props: ['modal'],


    methods: {

        closeModal: function() {
            this.modal.show = false;
        }
    }

}
</script>

<style>

@keyframes fade-in-modal {
    0% {
        top: -500px;
        opacity: 0;
    }
    40%{
        opacity: 0;
    }
    100% {
        top: 50%;
        opacity: 1;
    }
}

.modal-wrap {

    text-align: center;

    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 2em 1em;
    width: 60vw;

    position: fixed;
    bottom: -500px;
    left: 0;
    right: 0;
    margin: 0 auto;

    opacity: 0;


    background: rgb(250, 250, 250);
    border-radius: 5px;
    box-shadow: -2px 3px 6px 0 rgba(0, 0, 0, 0.4);

    transition: bottom 500ms ease, opacity 200ms ease;
}

.modal-wrap.show {
    bottom: 200px;
    opacity: 1;
    transition: bottom 400ms ease, opacity 800ms ease;
}

.modal-wrap .modal-title {
    color: rgb(0,200,0);
}

.modal-wrap.error .modal-title {
    color: rgb(220, 0, 0);
}



.modal-title {
    margin-bottom: 1em;
}

.modal-body {
    margin-bottom: 1em;
}



.modal-btn {
    background: white;
    border: none;
    color: rgb(20,20,20);
    
}

</style>