import Page404 from './components/Page404'
import Home from './components/Home'
import Why from './components/Why'
import What from './components/What'
import Contact from './components/Contact'


export default [
    
    
    {
        path: '/',

        component: Home,

        meta: {
            title: 'Tabula Agency - Home'
        },
    },


    {
        path: '/why',

        component: Why,

        meta: {
            title: 'Tabula Agency - Why?'
        },
    },


    {
        path: '/what',

        component: What,

        meta: {
            title: 'Tabula Agency - What?'
        },
    },


    {
        path: '/contact',

        component: Contact,

        meta: {
            title: "Tabula Agency - Let's do it!"
        },
    },



    {path: '*', component: Page404}
]