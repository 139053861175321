<template>
    <div class="slider-wrap">

        <div class="slider-controls" v-if="canSlide">

            <span @click="prevSlide" class="slider-control left material-icons">chevron_left</span>
            <span @click="nextSlide" class="slider-control right material-icons">chevron_right</span>

            <div class="play-auto-slide">
                <span v-if="!playSlider" class="material-icons" @click="autoPlaySlider">play_circle</span>
                <span v-else class="material-icons" @click="pauseSlider">pause_circle</span>
            </div>

        </div>

        

        <div class="scroll-down" v-if="slider.scrollDown">
            <span class="material-icons" @click="scrollDown">arrow_back_ios_new</span>
        </div>

        <div id="Transition" :class="{ show : transition }"></div>

        <div id="Slider" :style="{ backgroundImage: 'url(' + slider.background + ')' }" :class="{ 'single-grid' : !slider.hasImage || !slider.hasText || mobile }">


            <div class="slider-text" v-if="slider.hasText" :class="{ show : textTransition, 'with-img' : slider.hasImage }">

                
                
                <h2 class="slider-heading" v-html="slider.heading"></h2>
                <p class="slider-text1">{{ slider.text1 }}</p>
                <p class="slider-text2">{{ slider.text2 }}</p>

                <div class="action" v-if="slider.hasAction">
                    <br>
                    <button class="btn call-to-action-btn" @click="scrollToElement(slider.actionID)">{{ slider.action }}</button>
                </div>  

                

            </div>

            


            <div class="slider-image" v-if="slider.hasImage" :class="{ show : imageTransition, 'size-up' : mobile && !slider.hasText, 'no-text' : !slider.hasText }">
                <img :src="slider.image" :alt="slider.alt">
            </div>

            

        </div>   

        
        
    </div>
</template>

<script>
export default {

    name: 'Slider',

    props: ['slides'],

    data() {
        return {

            mobile: false,
            
            // Play/Pause control
            playSlider: true,

            // Lightbox transition between slides (fade to black)
            transition: false,

            // Transition text changes
            textTransition: false,

            // Transition image changes
            imageTransition: true,

            // There is more than one side in the array
            canSlide: false,

            // Scroll to next element on page
            

            // Cancel an already loaded setTimeout - used when pause is clicked
            // to cancel setTimeout
            autoSlideTimer: '',



            slider: {

                hasText: false,
                hasImage: false,
                hasAction: false,
                scrollDown: false,

                background: '',
                heading: '',
                text1: '',
                text2: '',

                action: '',
                actionID: '',

                image: '',
                alt: '',
                
            },

        }

        
    },


    created() {

        if(window.innerWidth < 700) {
            this.mobile = true;
        }

    },

    mounted() {

        //this.transitionDiv = document.getElementById('Transition');
        // Initialise Slider
        this.startSlider();

        //console.log(this.slides);
        
    },


    methods: {





        startSlider: function() {
            
            // Set initial values
            this.slider = this.slides[0];

            // Initialize first text transition
            setTimeout(() => {
                this.textTransition = true;
            },100)

            // If there is more than one slide in the array
            if(this.slides.length > 1) {
                this.canSlide = true;
                // Initialize Auto-Slider
                this.autoSlide();
            }    
            
            
        },




        autoPlaySlider: function() {

            this.playSlider = true;
            this.nextSlide();
            this.autoSlide();

        },


        pauseSlider: function() {
            clearTimeout(this.autoSlideTimer);
            this.playSlider = false;
        },




        delay: function(time) {
            return new Promise(res => {
                setTimeout(() => {
                    res();
                },time)
            })
        },




        transitionOn: function() {
            return new Promise(res => {
                // Turn ON transition before timeout...
                this.transition = true;
                this.transitionZ = false;
                //this.textTransition = false;
                // Time until new background starts loading...
                setTimeout(() => {
                    res();
                },600)
            })
        },

        transitionOff: function() {
            return new Promise(res => {
                // Time before transition fades to OFF
                setTimeout(() => {
                    this.transition = false;
                    res();
                },400)
            })
        },




        nextSlide: async function() {



            var currentSlide = 0;
            var nextSlide = 0;


            // Loop through array and find the current slide
            this.slides.forEach((slide, index) => {
                
                // Assign current slide
                if(slide.heading === this.slider.heading) {
                    currentSlide = index;
                }

            });

        


            nextSlide = currentSlide + 1;


            // Check if the next slide exists in array, otherwise start over
            if(nextSlide === this.slides.length) {
                
                nextSlide = 0;

            }



            
            // Turn text off
            this.textTransition = false;




            // Change text and image only
            if( this.slides[currentSlide].background === this.slides[nextSlide].background &&
                this.slides[currentSlide].image === this.slides[nextSlide].image) {

                //console.log('Text change only...');
                

                // Await -> delay the text transition
                await this.delay(800)
                    .then(() => {

                        this.textTransition = true;

                        // Load new slide text only
                        this.slider = this.slides[nextSlide];

                        // fade transition lightbox to OFF
                        this.transitionOff()

                            // then transition the text
                            .then(() => {
                                this.textTransition = true;
                            })
                    })

            // Background and image stay the same - Only change/transition the text
            } else if(this.slides[currentSlide].background === this.slides[nextSlide].background) {

                this.imageTransition = false;
                //this.transitionZ = true;

                //console.log('changing text and image...');

                await this.delay(800)
                    .then(() => {
                        
                        this.textTransition = true;
                        this.imageTransition = true;
                        this.slider = this.slides[nextSlide];
                    })
                
            // Change everything
            } else {

                
                this.imageTransition = false;


                // Await -> Transition to turn ON -> then load new background (prevents flickering)
                await this.transitionOn()
                    .then(() => {

                        // Load new slide
                        this.slider = this.slides[nextSlide];

                        // fade transition lightbox to OFF
                        this.transitionOff()

                            // then transition the text
                            .then(() => {
                
                                this.textTransition = true;
                                this.imageTransition = true;
                
                            })
                    })


            }
   
        },







        prevSlide: async function() {


            var currentSlide = 0;
            var prevSlide = 0;


            // Loop through array and find the current slide
            this.slides.forEach((slide, index) => {
                
                // Assign current slide to var
                if(slide.heading === this.slider.heading) {
                    currentSlide = index;
                }

            });


            // If we are at the first slide start at the last slide in the array
            if(currentSlide === 0) {
                prevSlide = this.slides.length - 1;
            } else {
                // Assign the previous slide
                prevSlide = currentSlide - 1;
            }


            // Turn text off
            this.textTransition = false;



            // Change text and image only
            if(this.slides[currentSlide].background === this.slides[prevSlide].background) {



                await this.delay(800)
                    .then(() => {
                        
                        this.textTransition = true;
                        this.slider = this.slides[prevSlide];
                    })


            } else {

                // Await -> Transition ON -> then load new background (no flickering)
                await this.transitionOn()
                    .then(() => {
                        // Load new slide
                        this.slider = this.slides[prevSlide]
                        // fade transition to OFF
                        this.transitionOff()

                            .then(() => {
                                this.textTransition = true;
                            })
                    })
            }
        },






        autoSlide: function() {

            if(!this.playSlider) {
                return
            }

            this.autoSlideTimer = setTimeout(() => {
                this.nextSlide();
                this.autoSlide();
            }, 8000)

  
        },





        scrollDown: function() {
            var sliderHeight = document.getElementById('Slider').offsetHeight;
            window.scrollTo(0,sliderHeight);
        },




        scrollToElement: function(id) {
            var element = document.getElementById(id);
            window.scrollTo(0,element.offsetTop - 100);
            
        }



    }

}
</script>

<style>

.slider-wrap {
    width: 100vw;
    /*height: 100vh;*/
    background: rgb(80,80,80);
    z-index: 100;
    position: relative;

    display: flex;

    
}





#Transition {
    z-index: 899;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,1);

    position: absolute;
    top: 0;
    left: 0;

    opacity: 0;
    transition: z-index 1000ms ease, opacity 600ms ease;
}

#Transition.show {
    z-index: 901;
    opacity: 1;
    left: 0;
    transition: z-index 100ms ease, opacity 600ms ease;
}






#Slider {
    z-index: 900;
    width: 100%;
    height: calc(100vh - 100px);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;

    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-items: center;

    padding: 0 70px;

    position: relative;

}

#Slider.single-grid {
    grid-template-columns: 1fr;
    text-align: center;
}






.slider-control {
    z-index: 999;
    position: absolute;
    top: 50%;
    transform: translateY(50%);

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
}

.slider-control.left {
    left: 0.5em;
}

.slider-control.right {
    right: 0.5em;
}

.slider-control.material-icons {
    color: rgba(143, 244, 255, 0.8);
    font-size: 36px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.1);
    
}

.play-auto-slide {
    z-index: 999;
    position: absolute;
    bottom: 2em;
    left: 50%;
    transform: translateX(-50%);
}

.play-auto-slide span.material-icons {
    color: rgba(255, 255, 255, 0.1);
    font-size: 36px;
    cursor: pointer;
    transition: color 350ms ease;
}

.play-auto-slide span.material-icons:hover {
    color: rgba(255,255,255,0.8);
    transition: color 350ms ease;
}






@keyframes scroll-icon {
    0%{
        transform: translateY(-20px);
        opacity: 0;
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.scroll-down {
    z-index: 999;
    position: absolute;
    bottom: 0;
    width: 100%;

    display: flex;
    justify-content: center;
    /*transform: translateX(-50%);*/
    cursor: pointer;
    animation: scroll-icon 2000ms ease;
    animation-iteration-count: 3;
}

.scroll-down .material-icons {
    color: white;
    font-size: 36px;
    transform: rotate(-90deg);
}







/* Text styling */
.slider-text {
    color: white;
}

.slider-heading {
    font-weight: 700;
    font-size: 34px;
}

.slider-text1 {
    font-size: 26px;
    font-weight: 400;
}

.slider-text2 {
    font-size: 20px;
    font-weight: 200;
}



/* Text transition properties */
.slider-text {
    opacity: 0;
    transform: translateY(-50px);
    transition: transform 650ms ease 200ms, opacity 500ms ease 200ms;
}

.slider-text.show {
    opacity :1;
    transform: translateY(0px);
    transition: transform 850ms ease 200ms, opacity 700ms ease 200ms;
}






/* Image styling */
.slider-image {
    width: 400px;
    height: 400px;
    overflow: hidden;

    display: flex;
    align-items: center;
    justify-content: center;
}

.slider-image.no-text {
    width: 80%;
    height: 80%;
}

.slider-image img {
    max-width: 100%;
    max-height: 100%;
}



/* Image transition properties */
.slider-image {
    opacity: 0;
    transition: opacity 500ms ease 200ms;
}

.slider-image.show {
    opacity :1;
    transition: opacity 2000ms ease 350ms;
}



.action {
    z-index: 999 !important;
}

.call-to-action-btn {
    z-index: 999;
}






/* Mobile Styling */

@media (max-width: 700px) {


    .slider-wrap {
        display: flex;
        justify-content: center;
        align-items: center;
    }


    #Slider {
        text-align: center;
        padding: 0 2em;
        
    }


    .slider-control {
        top: 90%;
        transform: translateY(0);
    }

    .play-auto-slide {
        bottom: 1.5em;
    }


    .slider-text.with-img {
        align-self: flex-end;
    }


    .slider-image {
        width: 250px;
        height: 250px;

    }

    .slider-image.size-up {
        width: 100%;
        height: 80%;
        margin-top: 2em;
    }
}


</style>