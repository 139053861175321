<template>
  <div id="app">
    <Menu />
    <div class="menu-spacer"></div>
    <router-view class="page-fades"></router-view>
    <Footer />
  </div>
</template>






<script>

import Menu from './components/Menu'
import Footer from './components/Footer'


export default {
  name: 'App',
  components: {
    Menu,
    Footer
  }
}



</script>






<style>

/* Page transitions */

@keyframes fade-pages {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.page-fades {
  animation: fade-pages 800ms ease;
}

/* End page transitions */



/* Global resets and font */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'PT Sans', sans-serif;
  max-width: 100%;
  scroll-behavior: smooth;
  outline: none;
}

/* White space underneath fixed menu */
.menu-spacer {
  height: 80px;
}

/* Global button styling */
.btn {
  padding: 0.7em 1em;
  border: none;
  border-radius: 3px;
  background: rgb(0, 151, 165);
  color: white;
  font-size: 16px;
}

/* Global anchor-link styling */
a {
  text-decoration: none;
  color: rgb(0, 151, 165);
}

</style>
