<template>
    <div class="menu-wrap">

            <div class="toggle-nav-wrap" @click="toggleNav">
                <span class="toggle-nav"></span>
            </div>


            <div class="nav-wrap" v-if="isMobile">
                <ul :class="{ show: mobileMenu }">
                    <li :class="{ show: mobileMenu }" @click="toggleNav"><router-link to="/">Home</router-link></li>
                    <li :class="{ show: mobileMenu }" @click="toggleNav"><router-link to="/why">Why?</router-link></li>
                    <li :class="{ show: mobileMenu }" @click="toggleNav"><router-link to="/what">What?</router-link></li>
                    <li :class="{ show: mobileMenu }" @click="toggleNav"><router-link to="/contact">Let's do it!</router-link></li>
                </ul>    

                <router-link to="/">
                    <div class="nav-logo" :class="{ shrink : scrollActive || mobileMenu }">
                        <img src="../assets/tabula_nav_logo.svg" alt="tabula_nav_logo">
                    </div>
                </router-link>



            </div>


            <div class="nav-wrap" v-else>
                <ul class="nav-ul-left" :class="{ condense: scrollActive }">
                    <li><router-link to="/">Home</router-link></li>
                    <li><router-link to="/why">Why?</router-link></li>
                </ul>

                <router-link to="/">
                    <div class="nav-logo" :class="{ shrink : scrollActive }">
                        <img src="../assets/tabula_nav_logo.svg" alt="tabula_nav_logo">
                    </div>
                </router-link>


                <ul class="nav-ul-right" :class="{ condense : scrollActive }">
                    <li><router-link to="/what">What?</router-link></li>
                    <li><router-link to="/contact">Let's do it!</router-link></li>
                </ul>
            </div>


    </div>
</template>






<script>
export default {

    name: 'Menu',

    data() {
        return {
            scrollActive: false,
            isMobile: false,
            mobileMenu: false
        }
    },

    created() {
        
        // Check for scroll to animate menu 
        window.addEventListener('scroll', this.onScrollMenu);
        
        // check if on mobile
        if(window.innerWidth > 400) {
            this.isMobile = false;
        } else 
            this.isMobile = true;

        // Check again when resizing for responsiveness
        window.addEventListener('resize', this.toggleMobile);

        
    },


    methods: {

        toggleMobile: function() {
            
            if(window.innerWidth > 700) {
                this.isMobile = false;
            } else 
                this.isMobile = true;

        },


        onScrollMenu: function() {
            if(window.pageYOffset > 50) {
                this.scrollActive = true;
            } else {
                this.scrollActive = false;
            }
        },


        toggleNav: function() {
            this.mobileMenu = !this.mobileMenu
        }
    }

}



</script>






<style>




/* Desktop */

.toggle-nav {
    display: none;
}

.menu-wrap {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    height: 80px;
}






.nav-wrap {
    height: 80px;
    width: 100%;
    display: flex;
    position: relative;
    align-items: center;

    background: rgb(90,90,95);

    box-shadow: 0 3px 6px 0 rgba(0,0,0,0.4);
}




.nav-wrap ul {
    list-style-type: none;
}

.nav-wrap ul li {
    margin-right: 4em;
}




.nav-ul-left {
    float: left;
    width: 50%;
    padding-right: 150px;
    display: flex;
    justify-content: flex-end;
    transition: padding 500ms ease;
}

.nav-ul-left.condense {
    padding-right: 80px;
    transition: padding 350ms ease;
}

.nav-ul-left li:last-child {
    margin-right: 0;
}





.nav-ul-right {
    float: right;
    width: 50%;
    padding-left: 150px;
    display: flex;
    justify-content: flex-start;
    transition: padding 500ms ease;
}

.nav-ul-right.condense {
    padding-left: 80px;
    transition: padding 350ms ease;
}






.nav-logo {

    width: 140px;
    height: 140px;

    background: rgb(50,50,50);
    
    padding: 0.7em;

    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;

    box-shadow: -2px 5px 6px 0 rgba(0,0,0,0.4);

    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    margin: 0;

    transition: all 500ms ease;
}

.nav-logo.shrink {
    background: rgb(90,90,95);

    box-shadow: none;
    
    padding: 0.4em;
    width: 80px;
    height: 80px;
    transition: all 350ms ease;
}


.nav-logo img{
    width: 100%;
    height: 100%;
}







.nav-wrap ul li a {
    text-decoration: none;
    color: white;
    font-weight: 400;
    font-size: 14px;
    text-transform: uppercase;
}



/* Mobile */
@media (max-width: 700px) {



    .nav-wrap ul{
        position: absolute;
        top: 90px;
        right: -200px;

        
        z-index: 999;


        opacity: 0;
        transition: opacity 250ms ease, right 450ms ease;
    }

    .nav-wrap ul.show {
        opacity: 1;
        right: 0;
        width: 100%;
        transition: opacity 1000ms ease, right 350ms ease;
    }



    .nav-wrap ul li:nth-child(1) {
        top: 0;
    }
    .nav-wrap ul li:nth-child(2) {
        top: 45px;
    }
    .nav-wrap ul li:nth-child(3) {
        top: 90px;
    }
    .nav-wrap ul li:nth-child(4) {
        top: 135px;
    }


    .nav-wrap ul li {
        background: rgb(50,50,55);
        box-shadow: -4px 1px 4px 0 rgba(0,0,0,0.2);

        margin: 0;
        margin-bottom: 0.5em;
        padding: 0.5em;

        position: absolute;
        right: -200px;
        width: 150px;
        
        transition: right 500ms ease;
    }

    .nav-wrap ul li.show:nth-child(1) {
        top: 0px;
        right: -60px;
        transition: right 350ms ease;
    }

    .nav-wrap ul li.show:nth-child(2) {
        top: 45px;
        right: -45px;
        transition: right 450ms ease;
    }

    .nav-wrap ul li.show:nth-child(3) {
        top: 90px;
        right: -30px;
        transition: right 550ms ease;
    }

    .nav-wrap ul li.show:nth-child(4) {
        top: 135px;
        right: -15px;
        transition: right 650ms ease;
    }






    .toggle-nav-wrap {
        
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 1.5em;
        height: 45px;
        width: 20px;

        display: flex;
        justify-content: center;

        z-index: 999;
    }


    .toggle-nav, .toggle-nav::before, .toggle-nav::after {
        height: 7px;
        width: 7px;
        background: rgb(255,255,255);
    }

    .toggle-nav {
        display: block;
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);

    }

    .toggle-nav::before, .toggle-nav::after {
        content: '';
        position: absolute;
    }


    .toggle-nav::before {
        top: 15px;
    }

    .toggle-nav::after {
        top: -15px;
    }






    .nav-logo.shrink {
        transform: translateX(0%);
        left: 1em;
    }

}


</style>