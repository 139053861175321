<template>
    <div class="contact-wrap">


        <div class="contact-intro">
          <h2>Let’s do it!</h2>
 
          <p>Your brand/institution deserves to stand out amongst your competitors.</p> 
          <br>
          <p>Contact us for fresh ideas accompanied by striking visuals and underlined by a definitive strategy.</p>

        </div>




        <div class="contact-form">
          
          <h4 class="input-heading" :class="{ error : error.name }">Name</h4>
          <input name="name" id="InputName" type="text" v-model="contact.name" :class="{ error : error.name }" @input="error.name = false">

          <h4 class="input-heading" :class="{ error : error.lastname }">Last name</h4>
          <input name="last-name" type="text" v-model="contact.lastname" :class="{ error : error.lastname }" @input="error.lastname = false">

          <h4 class="input-heading" :class="{ error : error.email }">Email</h4>
          <input name="email" type="email" v-model="contact.email" :class="{ error : error.email }" @focus="contact.email = ''" @input="error.email = false">

          <h4 class="input-heading">Phone</h4>
          <input name="tel" type="number" v-model="contact.phone">
          
          <h4 class="input-heading">Company (if any)</h4>
          <input name="orginization" type="text" v-model="contact.company">

          <h4 class="input-heading" :class="{ error : error.service }">What can we assist you with?</h4>
          <p v-if="error.service" class="service-not-selected">Please select a service or select "Other" if you would like to describe what you need.</p>
          <select autocomplete="off" class="service-selection" v-model="contact.service" @input="error.service = false">
            <option disabled value="">Please select...</option>
            <option value="Consulting">Consulting</option>
            <option value="Strategy">Strategy</option>
            <option value="Brand Development">Brand Development</option>
            <option value="Photography">Photography</option>
            <option value="Video">Video</option>
            <option value="Motion graphics">Motion Graphics</option>
            <option value="Social Media">Social Media</option>
            <option value="Website">Website</option>
            <option value="Mobile Apps">Mobile App</option>
            <option value="Google Adwords">Google Adwords</option>
            <option value="Printing">Printed Media</option>
            <option value="Advertising">Advertising</option>
            <option value="PR">PR</option>
            <option value="Other">Other</option>
          </select>

          <div class="other-selected" v-if="contact.service === 'Other'">
            <h4 class="input-heading" :class="{ error : error.other }">Please specify the service you require</h4>
            <input autocomplete="off" type="text" v-model="contact.other" :class="{ error : error.other }" @focus="error.other = false">
          </div>

          <br>

          <h4 class="input-heading">Your Message</h4>
          <textarea class="text-area" v-model="contact.message"></textarea>

          <br><br>

          <vue-recaptcha sitekey="6LcSK10aAAAAADJdsIxjcsoU-9OGuXtOjrDJ8llM">
            <button class="btn btn-submit" @click="submitContact">Submit</button>
          </vue-recaptcha>

        </div>

        <Modal :modal="modal"/>
    </div>
</template>




<script>

import VueRecaptcha from 'vue-recaptcha';
import axios from 'axios';
import Modal from './Modal';


export default {

  name: 'Contact',

  components: {
    VueRecaptcha,
    Modal
  },

  data() {
    return {

      modal: {},

      contact: {
        name: '',
        lastname: '',
        company: '',
        email: '',
        phone: '',
        service: '',
        other: '',
        message: ''
      },

      temp: '',

      error: {
        name: false,
        lastname: false,
        email: false,
        service: false,
        other: false
      },

      regexEmail: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/

    }
  },


  methods: {

    canSubmit: function() {

      var flag = true;

      if(!this.regexEmail.test(this.contact.email)) {
          this.error.email = true;
          this.contact.email = 'Please enter a valid email address';
          flag = false;
      }

      if(this.contact.name === '') {
        this.error.name = true;
        flag = false;
      }

      if(this.contact.lastname === '') {
        this.error.lastname = true;
        flag = false;
      }

      if(this.contact.service === '') {
        this.error.service = true;
        flag = false;
      }

      if(this.contact.service === 'Other' && this.contact.other === '') {
        this.error.other = true;
        flag = false;
      }

      return  flag;

    },




    submitContact: function() {

      
      if(!this.canSubmit()) {
        this.modal = {
            show: true,
            title: 'Please fill in all required fields.',
            body: '',
            error: true
        }

        window.scrollTo(0,document.getElementById('InputName').offsetHeight + 150);
        return
      }

      axios.post('contact.php', this.contact, {
          headers: {'Content-type' : 'application/x-www-form-urlencoded'}
      })
          .then(resp => {

              
              if(resp.data.status === 'success') {
                  this.modal = {
                      show: true,
                      title: 'Your message has been sent!',
                      body: 'Thank you for your enquiry, we will get back to you very soon.',
                      error: false
                  }

                  this.contact = {
                      name: '',
                      lastname: '',
                      company: '',
                      email: '',
                      phone: '',
                      service: '',
                      other: '',
                      message: ''
                  }

              } 
              
              else {
                  this.modal = {
                      show: true,
                      title: 'Oh no, your message could not be sent',
                      body: 'Please email us <a href="mailto:janette@tabula-agency.co.za">here</a>',
                      error: true
                  }
              }
              


          })

    }
  }

}
</script>




<style>

.grecaptcha-badge {
  z-index: 1000;
}

.contact-wrap {
  display: flex !important;
  flex-direction: column;
  justify-content: center ;
  align-items: center !important;
  text-align: left;

  position: relative;
}

.contact-intro {
  margin-top: 6em;
  margin-bottom: 4em;
  text-align: center;
}

.contact-intro h2 {
  margin-bottom: 1.5em;
}

.contact-form {
  
  margin-bottom: 4em;

  text-align: left;

  
}

.contact-form .input-heading {
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 0.2em;
  text-align: left;
}

.input-heading.error {
  color: rgb(180,0,0);
}

.contact-form input {
  border: none;
  outline: none;
  border-bottom: 1px solid rgb(50,50,50);
  margin-bottom: 3em;
  width: 50vw;

  text-align: left;
}

.contact-form input.error {
  border-bottom: 1px solid rgb(180,0,0);
}

.text-area {
  width: 50vw;
  height: 100px;
}

.service-selection {
  margin-bottom: 3em;
  width: 50vw;
  
}

.service-not-selected {
  margin-top: 0.2em;
  margin-bottom: 0.8em;
  color: rgb(180,0,0);
  font-size: 12px;
}

.btn-submit {
  display: block;
  
}

#Temp ul{
  list-style-type: none;
}

@media (max-width: 700px) {

  .contact-form input, .service-selection, .text-area {
    width: 65vw;
  }

  .contact-form {
    padding: 0 2em;
  }


}

</style>