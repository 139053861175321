import Vue from 'vue'
import App from './App.vue'
import router from './router.js'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(VueAxios, axios);


Vue.config.productionTip = false

if(process.env.NODE_ENV === 'production') {
  //console.log('prod');
  axios.defaults.baseURL = 'https://tabula-agency.co.za/api/';
} else if(process.env.NODE_ENV === 'development'){
  //console.log('dev');
  axios.defaults.baseURL = 'http://localhost/tabula/api/';
}



new Vue({
  render: h => h(App),
  router
}).$mount('#app')
