<template>
  <div class="what-wrap">

    <div class="slider-wrapper">
      <Slider :slides="slides" :sliderOptions="sliderOptions"/>
    </div>



    <div class="what-content">


      <div id="StarterPacks" class="what-section">
        <h4 class="section-heading">Starter pack for new brands</h4>
        <div class="section-content">
          <p>We will help you to kick off with initial brand development, website, communication, social media and PR strategies, as well as advertising and lead generation via various channels.</p> 
        </div>  
      </div>
      


      <div id="Brand" class="what-section">
        <h4 class="section-heading">Brand Positioning</h4>
        <div class="section-content">
          <p>Brand positioning, visual identity and messaging matrix</p>
        </div>
      </div>


      
      <div id="Campaigns" class="what-section">
        <h4 class="section-heading">Once-off Campaigns</h4>
        <div class="section-content">
          <p id="Campaigns">Once-off creative campaigns – concept and execution</p> 
        </div>
      </div>
      


      <div class="services">
        <h4>Our services:</h4>
        <ul>
          <li>Consulting</li>
          <li>Strategy</li>
          <li>Brand development</li>
          <li>Photography</li>
          <li>Video <br><span class="soft-text">(from script to final production)</span></li>
          <li>Motion graphics</li>
          <li>Social media strategy and content</li>
          <li>Website development and content</li>
          <li>Google Adwords</li>
          <li>Printed media <br><span class="soft-text">(flyers, brochures, product info sheets, posters, business cards etc.)</span></li>
          <li>Advertising</li>
          <li>PR strategy and content</li>
          <li>App design and development</li>
        </ul>
      </div>


    </div>


  </div>
</template>




<script>

import Slider from './Slider'


export default {
    name: 'What',

    components: {
      Slider
    },

    data() {
    return {


      sliderOptions: {
        scrollIcon: true
      },


      slides : [

        {
          hasImage: true,
          hasText: true,
          hasAction: true,
          scrollDown: true,

          background: "https://tabula-agency.co.za/slider/SlateBGLargeNew.jpg",

          heading: 'Starter Pack',
          text1: '',
          text2: '',

          action: 'Find out more',
          actionID: 'StarterPacks',

          image: 'https://tabula-agency.co.za/images/tabula_services.png',
          alt: 'tabula-services'
        },

        {
          hasImage: true,
          hasText: true,
          hasAction: true,
          scrollDown: true,

          background: "https://tabula-agency.co.za/slider/SlateBGLargeNew.jpg",

          heading: 'Brand positioning',
          text1: '',
          text2: '',

          action: 'Find out more',
          actionID: 'Brand',

          image: 'https://tabula-agency.co.za/images/tabula_services.png',
          alt: 'tabula-services'
        },

        {
          hasImage: true,
          hasText: true,
          hasAction: true,
          scrollDown: true,

          background: "https://tabula-agency.co.za/slider/SlateBGLargeNew.jpg",

          heading: 'Once-off campaigns',
          text1: '',
          text2: '',

          action: 'Find out more',
          actionID: 'Campaigns',

          image: 'https://tabula-agency.co.za/images/tabula_services.png',
          alt: 'tabula-services'
        }
      ]

    }
  },
}
</script>

<style>

.what-content {
  margin-top: 5em;
  text-align: center;
  padding: 0 2em;
  margin-bottom: 6em;
}

.what-section {
  margin-bottom: 3em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.section-heading {
  margin-bottom: 1em;
}

.section-content {
  padding: 2em 3em;
  border: 1px solid rgb(0,167,181);
  width: 80%;
}

.services h4{
  margin-bottom: 1em;
}

.services ul {
  list-style-type: none;
}

.services ul li{
  margin-bottom: 0.8em;
  position: relative;
}

.soft-text {
  color: rgba(0,0,0,0.6);
  font-size: 14px;
}


</style>