<template>
  <div class="footer-wrap">
      <p>Copyright &#169; {{ year }} - Tabula Agency</p>
  </div>
</template>

<script>
export default {

    name: 'Footer',

    computed: {
        year() {
            return new Date().getFullYear();
        }
    }

}
</script>

<style>

.footer-wrap {
    z-index: 999;

    position: fixed;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 20px;

    background: rgb(60,60,60);
    color: white;
    font-size: 10px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

</style>