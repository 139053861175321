import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes.js'

Vue.use(VueRouter);

const DEFAULT_TITLE = 'Tabula Agency'

const router = new VueRouter({
    mode: 'history',
    routes: routes,
    scrollBehavior () {
        return { x: 0, y: 0 };
      }
})

// Apply Document Title from routes meta   OR   default to "Tabula Agency"
router.afterEach((to) => {
    Vue.nextTick(() => {
        document.title = to.meta.title || DEFAULT_TITLE;
    });
    

})

export default router;