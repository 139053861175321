<template>
  <div class="why-wrap">


    <div class="slider-wrapper">
      <Slider :slides="slides" :sliderOptions="sliderOptions" />
    </div>


    <div class="why-content">


      <div class="why-intro">
        <h2>Tabula Brand Analysis</h2>
        <p>Answer the following questions, giving your brand/institution scores of 1 to 5, where 1 is spot-on and 5 is not so true</p>
      </div>



      <div class="questions">


        <div class="question">
          <h4>Your brand/institution is the talk of the town</h4>

          <div class="radio-wrap">
            <span class="radio-btn"> 1 <input type="radio" name="talkOfTown" value="1" v-model="q1"/> </span>
            <span class="radio-btn"> 2 <input type="radio" name="talkOfTown" value="2" v-model="q1"/> </span>
            <span class="radio-btn"> 3 <input type="radio" name="talkOfTown" value="3" v-model="q1"/> </span>
            <span class="radio-btn"> 4 <input type="radio" name="talkOfTown" value="4" v-model="q1"/> </span>
            <span class="radio-btn"> 5 <input type="radio" name="talkOfTown" value="5" v-model="q1"/> </span>
          </div>
        </div>


        <div class="question">
          <h4>Your brand/institution is distinctive and stands out</h4>
          
          <div class="radio-wrap">
            <span class="radio-btn"> 1 <input type="radio" name="distinctive" value="1" v-model="q2"/> </span>
            <span class="radio-btn"> 2 <input type="radio" name="distinctive" value="2" v-model="q2"/> </span>
            <span class="radio-btn"> 3 <input type="radio" name="distinctive" value="3" v-model="q2"/> </span>
            <span class="radio-btn"> 4 <input type="radio" name="distinctive" value="4" v-model="q2"/> </span>
            <span class="radio-btn"> 5 <input type="radio" name="distinctive" value="5" v-model="q2"/> </span>
          </div>
        </div>


        <div class="question">
          <h4>Your brand/institution has shown extraordinary growth recently</h4>
          
          <div class="radio-wrap">
            <span class="radio-btn"> 1 <input type="radio" name="growth" value="1" v-model="q3"/> </span>
            <span class="radio-btn"> 2 <input type="radio" name="growth" value="2" v-model="q3"/> </span>
            <span class="radio-btn"> 3 <input type="radio" name="growth" value="3" v-model="q3"/> </span>
            <span class="radio-btn"> 4 <input type="radio" name="growth" value="4" v-model="q3"/> </span>
            <span class="radio-btn"> 5 <input type="radio" name="growth" value="5" v-model="q3"/> </span>
          </div>
        </div>


        <div class="question">
          <h4>Employees are proud and happy to represent your brand/institution</h4>
          
          <div class="radio-wrap">
            <span class="radio-btn"> 1 <input type="radio" name="employees" value="1" v-model="q4"/> </span>
            <span class="radio-btn"> 2 <input type="radio" name="employees" value="2" v-model="q4"/> </span>
            <span class="radio-btn"> 3 <input type="radio" name="employees" value="3" v-model="q4"/> </span>
            <span class="radio-btn"> 4 <input type="radio" name="employees" value="4" v-model="q4"/> </span>
            <span class="radio-btn"> 5 <input type="radio" name="employees" value="5" v-model="q4"/> </span>
          </div>
        </div>


        <div class="question">
          <h4>Customers are proud and content to be associated with your brand/institution</h4>
          
          <div class="radio-wrap">
            <span class="radio-btn"> 1 <input type="radio" name="customers" value="1" v-model="q5"/> </span>
            <span class="radio-btn"> 2 <input type="radio" name="customers" value="2" v-model="q5"/> </span>
            <span class="radio-btn"> 3 <input type="radio" name="customers" value="3" v-model="q5"/> </span>
            <span class="radio-btn"> 4 <input type="radio" name="customers" value="4" v-model="q5"/> </span>
            <span class="radio-btn"> 5 <input type="radio" name="customers" value="5" v-model="q5"/> </span>
          </div>
        </div>


        <br>

        <button class="btn why-submit-btn" @click="submitQuestions">Get Result</button>

      </div>


      <div class="results">
        <p>{{ score }}</p>
        <br>
        <p>{{ result.content }}</p>
        <p class="contact-link" v-if="result.contact"><router-link to="/contact">Get in touch</router-link></p>
      </div>



    </div>


  </div>
</template>




<script>

import Slider from './Slider'


export default {

  name: 'Why',

  components: {
    Slider
  },

  data() {
    return {

      q1: 0,
      q2: 0,
      q3: 0,
      q4: 0,
      q5: 0,
      score: '',
      result: {
        content: '',
        contact: false
      },




      sliderOptions: {
        scrollIcon: true
      },


      slides : [
        {
          hasImage: true,
          hasText: false,
          hasAction: false,
          scrollDown: true,

          background: "https://tabula-agency.co.za/slider/SlateBGLargeNew.jpg",

          heading: '',
          text1: '',
          text2: '',

          action: '',

          image: 'https://tabula-agency.co.za/images/creative_to_clean_slate.png',
          alt: 'tabula-creative-to-clean-slate'
        }
      ]



    }
  },

  created() {

    if(window.innerWidth < 700) {
      this.slides[0].image = 'https://tabula-agency.co.za/images/mobile_creative_to_clean_slate.png'
    } else {
      this.slides[0].image = 'https://tabula-agency.co.za/images/creative_to_clean_slate.png'
    }

  },


  methods: {
    submitQuestions: function() {

      // Reset output result on page
      this.result.content = '';

      //Calculate total score...
      var total = Number(this.q1) + Number(this.q2) + Number(this.q3) + Number(this.q4) + Number(this.q5);

      // Check the user has answered all the questions...
      if(this.q1 > 0 && this.q2 > 0 && this.q3 > 0 && this.q4 > 0 && this.q5 > 0) {
        // Set the total score on page
        this.score = 'Your score: ' + total;




        if(total >=5 && total <= 10) {
          this.result.content = 'Well done! Let us know when you want to expand'
        }

        else if (total >= 10 && total <= 15) {
          this.result.contact = true;
          this.result.content = 'You should consider tabula rasa marketing';
        }

        else if(total > 15) {
          this.result.contact = true;
          this.result.content = 'Fill in our contact form ASAP!';
        }


        window.scrollTo(0,document.body.scrollHeight);



      // Let the user know to answer all questions
      } else {

        this.score = 'Make sure you have answered all the questions...';

        window.scrollTo(0,document.body.scrollHeight);
        
      }



    }
  }



}

</script>




<style>

.why-wrap {
  margin-bottom: 5em;
}



.why-content {
  padding: 0 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}


.why-intro {
  margin-top: 2em;
  margin-bottom: 3em;
}

.why-intro h2 {
  margin-bottom: 2em;
}




.questions {
}

.question {
  margin-top: 1em;
  margin-bottom: 2.5em;
  border:1px solid rgb(0,167,181);
  padding: 1em;
}

.question h4 {
  margin-bottom: 1em;
}



.radio-wrap {
  /*width: 40vw;*/
  display: flex;
  justify-content: center;
}

.radio-wrap .radio-btn:last-child {
  margin-right: 0;
}

.radio-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 1.5em;
  
}


.results {
  margin-top: 3em;
}


</style>